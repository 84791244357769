"use client";

import styles from "./index.module.scss";
import { FC, useEffect } from "react";
import Link from "next/link";
import { ControlledAccordion, useAccordionProvider } from "@szhsin/react-accordion";
import { YOUTUBE_CHANNEL_LINK } from "@/shared/helpers/data/globalHelper";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { Icon } from "@/shared/components/Icon";
import Item from "@/shared/components/footer/components/item";
import { supportNumber, supportsNumberHref } from "@/shared/constants/constants";
const Accordion: FC = () => {
  const providerValue = useAccordionProvider({
    allowMultiple: true,
    transition: true,
    transitionTimeout: 250
  });
  const {
    toggleAll
  } = providerValue;
  const isDesktop = useMediaQuery("(min-width: 1023px)");
  useEffect(() => {
    isDesktop ? toggleAll(true) : toggleAll(false);
  }, [isDesktop]);
  return <ControlledAccordion className={styles.accordion} providerValue={providerValue} itemScope itemType="https://schema.org/SiteNavigationElement" data-sentry-element="ControlledAccordion" data-sentry-component="Accordion" data-sentry-source-file="index.tsx">
      <Item header="Покупателям" initialEntered data-sentry-element="Item" data-sentry-source-file="index.tsx">
        <ul className={styles.accordionList} itemProp="about" itemScope itemType="https://schema.org/ItemList">
          <li className={styles.accordionItem} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/license/" className={styles.accordionLink} itemProp="url" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              Правила лицензии
            </Link>
            <meta itemProp="name" content="Правила лицензии" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
          </li>
          <li className={styles.accordionItem} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/pay/" className={styles.accordionLink} itemProp="url" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              Оплата
            </Link>
            <meta itemProp="name" content="Оплата" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
          </li>
          <li className={styles.accordionItem} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/delivery/" className={styles.accordionLink} itemProp="url" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              Доставка
            </Link>
            <meta itemProp="name" content="Доставка" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
          </li>
          <li className={styles.accordionItem} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/faq/" className={styles.accordionLink} itemProp="url" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              FAQ
            </Link>
          </li>
          <li className={styles.accordionItem} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/return/" className={styles.accordionLink} itemProp="url" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              Обмен и возврат
            </Link>
            <meta itemProp="name" content="Обмен и возврат" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
          </li>
          <li className={styles.accordionItem} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/catalog/" className={styles.accordionLink} itemProp="url" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              Каталог
            </Link>
            <meta itemProp="name" content="Каталог" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
          </li>
          <li className={styles.accordionItem} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/license-department/" className={styles.accordionLink} itemProp="url" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              Стрелковые клубы и ЛРО
            </Link>
            <meta itemProp="name" content="Обмен и возврат" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
          </li>
          <li className={styles.accordionItem} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/partners/" className={styles.accordionLink} itemProp="url" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              Поставщикам
            </Link>
            <meta itemProp="name" content="Поставщикам" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
          </li>
          <li className={styles.accordionItem} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/opt/" className={styles.accordionLink} itemProp="url" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              Опт
            </Link>
            <meta itemProp="name" content="Опт" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
          </li>
        </ul>
      </Item>
      <Item header="О компании" initialEntered data-sentry-element="Item" data-sentry-source-file="index.tsx">
        <ul className={styles.accordionList} itemProp="about" itemScope itemType="https://schema.org/ItemList">
          <li className={styles.accordionItem} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/contacts/" className={styles.accordionLink} itemProp="url" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              Контакты
            </Link>
            <meta itemProp="name" content="Контакты" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
          </li>
          <li className={styles.accordionItem} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/vacancy/" className={styles.accordionLink} itemProp="url" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              Вакансии
            </Link>
            <meta itemProp="name" content="Вакансии" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
          </li>
          <li className={styles.accordionItem} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/brands/" className={styles.accordionLink} itemProp="url" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              Бренды
            </Link>
            <meta itemProp="name" content="Бренды" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
          </li>
          <li className={styles.accordionItem} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/about/" className={styles.accordionLink} itemProp="url" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              О компании
            </Link>
            <meta itemProp="name" content="О компании" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
          </li>
          <li className={styles.accordionItem} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/policy/" className={styles.accordionLink} itemProp="url" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              Политика конф.
            </Link>
            <meta itemProp="name" content="Политика конфиденциальности" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
          </li>
          <li className={styles.accordionItem} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/rules-for-posting-reviews/" className={styles.accordionLink} itemProp="url" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              Правила размещения отзывов
            </Link>
            <meta itemProp="name" content="Правила размещения отзывов" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
          </li>
          <li className={styles.accordionItem} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/rules-for-comission/" className={styles.accordionLink} itemProp="url" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              Правила размещения объявлений
            </Link>
            <meta itemProp="name" content="Правила размещения объявлений" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
          </li>
        </ul>
      </Item>
      <div>
        <Item header="Контакты" initialEntered data-sentry-element="Item" data-sentry-source-file="index.tsx">
          <ul className={styles.accordionList}>
            <li className={styles.accordionItem}>
              <Link prefetch={false} href={supportsNumberHref} className={styles.accordionLink} onClick={() => ym(42989679, "reachGoal", "clickOnTel")} data-sentry-element="Link" data-sentry-source-file="index.tsx">
                {supportNumber}
              </Link>
            </li>
            <li className={styles.accordionItem}>
              <Link prefetch={false} href="mailto:info@ohotaktiv.ru" className={styles.accordionLink} data-sentry-element="Link" data-sentry-source-file="index.tsx">
                info@ohotaktiv.ru
              </Link>
            </li>
            <li className={`${styles.accordionItem} ${styles.work}`}>Ежедневно с 7:00 - 22:00</li>
          </ul>
        </Item>
        <ul className={styles.accordionSocialList}>
          <li className={styles.accordionSocialItem}>
            <Link prefetch={false} href="https://vk.com/ohotaktiv" className={styles.accordionSocialLink} target="_blank" rel="noopener nofollow" title="Подписаться на Вконтакте" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              <Icon name={"Vk"} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
            </Link>
          </li>
          <li className={styles.accordionSocialItem}>
            <Link prefetch={false} href="https://t.me/ohotaktiv_official" className={styles.accordionSocialLink} target="_blank" rel="noopener nofollow" title="Подписаться на Телеграм" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              <Icon name={"Tg"} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
            </Link>
          </li>
          <li className={styles.accordionSocialItem}>
            <Link prefetch={false} href="https://zen.yandex.ru/id/61d959165d11a84299ef6cec" className={styles.accordionSocialLink} target="_blank" rel="noopener nofollow" title="Подписаться на Яндекс.Дзен" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              <Icon name={"Dzen"} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
            </Link>
          </li>
          <li className={styles.accordionSocialItem}>
            <Link prefetch={false} href="https://ok.ru/ohotaktiv" className={styles.accordionSocialLink} target="_blank" rel="noopener nofollow" title="Подписаться на Одноклассники" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              <Icon name={"Ok"} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
            </Link>
          </li>
          <li className={styles.accordionSocialItem}>
            <Link prefetch={false} href={YOUTUBE_CHANNEL_LINK} className={styles.accordionSocialLink} target="_blank" rel="noopener nofollow" title="Подписаться на Ютуб" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              <Icon name={"Youtube"} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
            </Link>
          </li>
          <li className={styles.accordionSocialItem}>
            <Link prefetch={false} href="https://rutube.ru/channel/24618421/" className={styles.accordionSocialLink} target="_blank" rel="noopener nofollow" title="Подписаться на Рутуб" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              <Icon name={"Rutube"} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
            </Link>
          </li>
        </ul>
      </div>
    </ControlledAccordion>;
};
export default Accordion;