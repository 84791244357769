import { Icon } from "@/shared/components/Icon";
import styles from "./index.module.scss";
const MobileStores = () => {
  return <div className={styles.download} data-sentry-component="MobileStores" data-sentry-source-file="index.tsx">
      <ul className={styles.list}>
        <li className={styles.item}>
          <a href="https://apps.apple.com/app/%D0%BE%D1%85%D0%BE%D1%82%D0%B0%D0%BA%D1%82%D0%B8%D0%B2-%D0%BE%D1%85%D0%BE%D1%82%D0%B0-%D1%80%D1%8B%D0%B1%D0%B0%D0%BB%D0%BA%D0%B0/id6502176237" className={styles.link} target="_blank" rel="noopener nofollow">
            <Icon name={"AppStoreMini"} className={styles.icon} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
          </a>
        </li>
        <li className={styles.item}>
          <a href="https://play.google.com/store/apps/details?id=comm.ohotaktiv" className={styles.link} target="_blank" rel="noopener nofollow">
            <Icon name={"GooglePlayMini"} className={styles.icon} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
          </a>
        </li>
        <li className={styles.item}>
          <a href="https://rustore.ru/catalog/app/com.ohotaktiv" className={styles.link} target="_blank" rel="noopener nofollow">
            <Icon name={"RustoreMini"} className={styles.icon} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
          </a>
        </li>
      </ul>
    </div>;
};
export default MobileStores;