"use client";

import { FC } from "react";
import styles from "./index.module.scss";
import { Icon } from "@/shared/components/Icon";
import Accordion from "@/shared/components/footer/components/accordion";
import Form from "@/shared/components/footer/components/form";
import MobileStores from "@/shared/components/MobileStores";
interface IFooter {
  customClassName?: string;
}
const Footer: FC<IFooter> = props => {
  const {
    customClassName = ""
  } = props;
  return <footer className={`${styles.footer} ${customClassName}`} data-sentry-component="Footer" data-sentry-source-file="index.tsx">
      <div className="container">
        <div className={styles.footerWrap}>
          <Accordion data-sentry-element="Accordion" data-sentry-source-file="index.tsx" />
          <div className={styles.footerRight}>
            <div className={styles.footerFormWrap}>
              <h3 className={styles.footerTitle}>Новости и акции только для своих</h3>
              <Form data-sentry-element="Form" data-sentry-source-file="index.tsx" />
            </div>
            <ul className={styles.footerPayList}>
              <li className={styles.footerPayItem}>
                <Icon name={"Visa"} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
              </li>
              <li className={styles.footerPayItem}>
                <Icon name={"Mir"} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
              </li>
              <li className={styles.footerPayItem}>
                <Icon name={"Mastercard"} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
              </li>
            </ul>
            <MobileStores data-sentry-element="MobileStores" data-sentry-source-file="index.tsx" />
          </div>
        </div>
      </div>
    </footer>;
};
export default Footer;