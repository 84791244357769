import { AccordionItem } from "@szhsin/react-accordion";
import styles from "./index.module.scss";
import { Icon } from "@/shared/components/Icon";
const Item = ({
  header,
  ...rest
}: any) => <AccordionItem {...rest} header={<>
        {header}
        <Icon name={"Chevron"} className={styles.chevron} />
      </>} className={rest.className ? styles.item + " " + rest.className : styles.item} buttonProps={{
  className: ({
    isEnter
  }) => `${styles.itemButton} ${isEnter && styles.itemButtonOpened}`
}} contentProps={{
  className: styles.itemContent
}} panelProps={{
  className: styles.itemPanel
}} data-sentry-element="AccordionItem" data-sentry-component="Item" data-sentry-source-file="index.tsx" />;
export default Item;